.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

form {
  width: 60%;
  padding-left: 20%;
}

input {
  margin-top: 1em;
}

.App-link {
  color: #61dafb;
}
